<script lang="ts">
  import { setLocale, locale } from "@/i18n/i18n-svelte";

  import { languages, languageMap } from "./constants";
  import { onMount } from "svelte";
  import { normalizeLng } from "./helper";
  import clsx from "clsx";

  export let selectedLang = localStorage.getItem("I18N_LANGUAGE") || "en";
  export let classes = "left-0 bottom-0";

  let isOpen = false;

  function handleLocaleChange(lang) {
    setLocale(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    selectedLang = lang;
    isOpen = false;
  }

  onMount(() => {
    setLocale(normalizeLng(selectedLang));
  });

 
</script>

<div class="relative inline-block text-left">
  <div
    class:hidden={!isOpen}
    class={clsx(
      "absolute  z-20 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
      classes
    )}
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div class="py-1" role="none">
      {#each languages as language}
        <a
          href="#"
          on:click={() => handleLocaleChange(language.value)}
          class:text-gray-900={selectedLang == language.value}
          class:bg-gray-100={selectedLang == language.value}
          class="text-gray-700 group flex items-center px-4 py-2 text-sm hover:bg-gray-200"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
        >
          <img
            alt=""
            class="h1-8 w-8 rounded-full mr-3 h-5 w-5 text-gray-900 group-hover:text-gray-500"
            src={language.flag}
          />
          {language.label}
        </a>
      {/each}
    </div>
  </div>


</div>
<button
on:click={() => (isOpen = !isOpen)}
type="button"
class=" inline-flex w-full justify-center gap-x-1.5 rounded-md text-sm font-semibold text-gray-900 shadow-sm"
id="menu-button"
aria-expanded="true"
aria-haspopup="true"
>
<img
  alt=""
  class=" w-6 rounded-full"
  src={languageMap[selectedLang].flag}
/>
</button>